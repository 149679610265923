(function () {
  'use strict';

  /* global $, document */
  class HeaderCtrl {

    constructor(Uos, $timeout, FeatureFlag, SolucoesEnum, Authenticator, $window) {
      this.sistema = 'core-conducaoeconomica';
      this.featureFlag = FeatureFlag;
      this.solucoesEnum = SolucoesEnum;
      this.authenticator = Authenticator;
      this.window = $window;
      this.showMenu = true;
      if (this.window.parent) {
        this.window.parent.postMessage({origin: 'vfleets', type: 'loaded'}, '*');
      }

      this.uo = {
        id: -1,
        nome: 'Carregando UOs...'
      };
      Uos.getFilhas().then(data => angular.extend(this.uo, data.plain()));

      $timeout(() => {
        $.AdminLTE.tree('.sidebar');
      });
      this.authenticator.getUser().then(user => {
        const localStrgBuscaRegiao = this.window.localStorage.getItem('tutorial-busca-regiao');
        this.showModalTutorialBuscaRegiao = (localStrgBuscaRegiao === null || localStrgBuscaRegiao < new Date()) &&
          user.idioma === 'PT_BR' &&
          this.featureFlag.TUTORIAL_BUSCA_REGIAO;
        if (user.permissoes.includes('VPLAYER_DOWNLOAD_VISUALIZAR')) {
          this.notificationVideoDownload(user);
        }
      });
      this.initHeader();
    }

    initHeader() {
      this.menu = [
        {label: 'ce.indicadores.menuLabel', icon: 'fa-bar-chart', roles: ['CONDUCAO_ECONOMICA_VISUALIZAR', 'DIRECAO_SEGURA_VISUALIZAR', 'OCIOSIDADE_FROTA_VISUALIZAR', 'NOTIFICACOES_VISUALIZAR', 'INDICADOR_POWER_BI_VISUALIZAR'],
          submenu: [
            {label: 'ce.indicadores.conducaoEconomica.menuLabel', state: 'root.conducaoEconomica', role: 'CONDUCAO_ECONOMICA_VISUALIZAR'},
            {label: 'ce.indicadores.direcaoSegura.menuLabel', state: 'root.direcaoSegura', role: 'DIRECAO_SEGURA_VISUALIZAR'},
            {label: 'ce.indicadores.ociosidadeFrota.menuLabel', state: 'root.utilizacaoVeiculo', role: 'OCIOSIDADE_FROTA_VISUALIZAR'},
            {label: 'BI', state: 'root.indicadorPowerBi', role: 'INDICADOR_POWER_BI_VISUALIZAR'}
          ]
        },
        {label: 'ce.tempoReal.menuLabel', icon: 'trimble-icon trimble-real-time', roles: ['OCORRENCIA_VISUALIZAR', 'ONE_PAGE_VISUALIZAR', 'RANKING_INFRACOES_VISUALIZAR'],
          submenu: [
            {label: 'ce.tempoReal.quadroDeOcorrencias.menuLabel', state: 'root.quadroOcorrencias', role: 'OCORRENCIA_VISUALIZAR'},
            {label: 'ce.tempoReal.statusFrota.menuLabel', state: 'root.onePage', role: 'ONE_PAGE_VISUALIZAR'},
            {label: 'ce.tempoReal.infracoes.menuLabel', state: 'root.rankingInfracoes', role: 'RANKING_INFRACOES_VISUALIZAR'}
          ]
        },
        {label: 'ce.ranking.menuLabel', icon: 'fa-sort-amount-desc', roles: ['RANKING_GERAL_VISUALIZAR', 'RANKING_ECONOMIA_VISUALIZAR', 'RANKING_SEGURANCA_VISUALIZAR', 'DADOS_SENSIVEIS_VISUALIZAR'],
          submenu: [
            {label: 'ce.ranking.pontos.menuLabel', state: 'root.rankingPontos', roles: [['RANKING_GERAL_VISUALIZAR', 'DADOS_SENSIVEIS_VISUALIZAR']]},
            {label: 'ce.ranking.conducaoEconomica.menuLabel', state: 'root.rankingEconomy', role: 'RANKING_ECONOMIA_VISUALIZAR'},
            {label: 'ce.ranking.direcaoSegura.menuLabel', state: 'root.rankingSafety', role: 'RANKING_SEGURANCA_VISUALIZAR'}
          ]
        },
        {label: 'ce.boletim.menuLabel', icon: 'fa-list-alt', roles: ['BOLETIM_MOTORISTA_VISUALIZAR', 'BOLETIM_VEICULO_VISUALIZAR', 'BOLETIM_SERVICO_VISUALIZAR', 'DADOS_SENSIVEIS_VISUALIZAR'],
          submenu: [
            {
              label: 'ce.boletim.diarioMotorista.menuLabel',
              state: 'root.bdm',
              roles: [['BOLETIM_MOTORISTA_VISUALIZAR', 'DADOS_SENSIVEIS_VISUALIZAR']]
            },
            {label: 'ce.boletim.diarioVeiculo.menuLabel', state: 'root.bdv', role: 'BOLETIM_VEICULO_VISUALIZAR'},
            {label: 'ce.boletim.abastecimento.menuLabel', state: 'root.abastecimento', role: 'HISTORICO_ABASTECIMENTO_VISUALIZAR'},
            {label: 'ce.boletim.servicos.menuLabel', state: 'root.bds', role: 'BOLETIM_SERVICO_VISUALIZAR'}
          ]
        },
        {
          label: 'ce.analise.menuLabel',
          icon: 'fa-line-chart',
          roles: [
            'HISTORICO_CONSOLIDADO_VISUALIZAR',
            'HISTORICO_ALERTA_VISUALIZAR',
            'PERMANENCIA_PONTO_REFERENCIA_VISUALIZAR',
            'PERMANENCIA_CERCA_ELETRONICA_VISUALIZAR',
            'ANALISE_VELOCIDADE_RPM_PARABRISA_VISUALIZAR',
            'SAUDE_FROTA_VISUALIZAR',
            'MOTORISTA_MENU_VISUALIZAR',
            'DADOS_SENSIVEIS_VISUALIZAR',
            'VPLAYER_REQMANUAL_VISUALIZAR'
          ],
          submenu: [
            {label: 'ce.analise.movimentoVeiculo.menuLabel', state: 'root.analiseOp', role: 'HISTORICO_CONSOLIDADO_VISUALIZAR'},
            {label: 'ce.analise.permanencia.menuLabel', state: 'root.permanencia', roles: ['PERMANENCIA_PONTO_REFERENCIA_VISUALIZAR', 'PERMANENCIA_CERCA_ELETRONICA_VISUALIZAR']},
            {label: 'ce.analise.alertas.menuLabel', state: 'root.historicoAlerta', role: 'HISTORICO_ALERTA_VISUALIZAR'},
            {
              label: 'ce.analise.solicitacoesAgendadas.menuLabel',
              state: 'root.historicoSolicitacao',
              roles: ['SOLICITACAO_AGENDADA_VISUALIZAR']
            },
            {label: 'ce.analise.velocidadeXrpm.menuLabel', state: 'root.velocidadeRpm', role: 'ANALISE_VELOCIDADE_RPM_PARABRISA_VISUALIZAR'},
            {label: 'ce.analise.saudeDaFrota.menuLabel', state: 'root.saudeFrota', role: 'SAUDE_FROTA_VISUALIZAR'},
            {
              label: 'ce.analise.identificacoes.menuLabel',
              state: 'root.identificacoes',
              roles: [['IDENTIFICACOES_VISUALIZAR', 'DADOS_SENSIVEIS_VISUALIZAR']]
            }
          ]
        },
        {label: 'ce.mapa.menuLabel', icon: 'fa-map', roles: ['LOCALIZACAO_ATUAL_VISUALIZAR', 'HISTORICO_DETALHADO_VISUALIZAR'],
          submenu: [
            {label: 'ce.mapa.localizacaoDaFrota.menuLabel', state: 'root.mapasGrid', role: 'LOCALIZACAO_ATUAL_VISUALIZAR'},
            {label: 'ce.mapa.historicoDetalhado.menuLabel', state: 'root.historicoTimeline', role: 'HISTORICO_DETALHADO_VISUALIZAR'},
            {label: 'ce.mapa.mapaDeCalor.menuLabel', state: 'root.mapaCalor', role: 'MAPA_DE_CALOR_VISUALIZAR'}
          ]
        },
        {label: 'ce.cadastro.menuLabel', icon: 'fa-pencil-square-o', roles: ['MOTORISTA_MENU_VISUALIZAR', 'VEICULO_VISUALIZAR', 'PONTO_REFERENCIA_VISUALIZAR'],
          submenu: [
            {label: 'ce.cadastro.motoristas.menuLabel', state: 'root.motorista', roles: [['MOTORISTA_MENU_VISUALIZAR', 'DADOS_SENSIVEIS_VISUALIZAR']]},
            {label: 'ce.cadastro.veiculos.menuLabel', state: 'root.veiculo', role: 'VEICULO_VISUALIZAR'},
            {label: 'ce.cadastro.grupoDeVeiculos.menuLabel', state: 'root.grupoVeic', role: 'GRUPO_VEICULO_VISUALIZAR'},
            {label: 'ce.cadastro.pontosReferencia.menuLabel', state: 'root.pontoReferencia', role: 'PONTO_REFERENCIA_VISUALIZAR'}
          ]
        },
        {label: 'ce.configuracao.menuLabel', icon: 'fa-cog', roles: ['CONFIGURACAO_ALERTA_VISUALIZAR', 'CONFIG_VDRIVERS_VISUALIZAR'],
          submenu: [
            {label: 'ce.configuracao.eventos.menuLabel', state: 'root.configEventos', role: 'CONFIGURACAO_ALERTA_VISUALIZAR'},
            {label: 'Vdrivers', state: 'root.configVdrivers', role: 'CONFIG_VDRIVERS_VISUALIZAR'}
          ]
        }
      ];

      this.addVideoLibrary();
      this.addMenuInsights();
    }

    addVideoLibrary() {
      const indiceMenuAnalise = 4,
          indiceSubmenuVideoLibrary = 4;
      if (this.featureFlag.VIDEO_LIBRARY) {
        this.authenticator.getUser().then((user) => {
          if (user.permissoes.indexOf('VPLAYER_REQMANUAL_VISUALIZAR') !== -1) {
            this.menu[indiceMenuAnalise].submenu.splice(indiceSubmenuVideoLibrary, 0, {
              label: 'ce.analise.videoLibrary.menuLabel',
              state: 'root.videoLibrary.solicitacaoVideos',
              roles: [['VPLAYER_REQMANUAL_VISUALIZAR', 'DADOS_SENSIVEIS_VISUALIZAR']]
            });
            this.menu[indiceMenuAnalise].submenu.splice(indiceSubmenuVideoLibrary, 0, {
              label: 'ce.analise.videoPlayback.menuLabel',
              state: 'root.playback',
              roles: [['VPLAYER_REQMANUAL_VISUALIZAR', 'DADOS_SENSIVEIS_VISUALIZAR']]
            });
          }
        });
      }
    }

    addMenuInsights() {
      this.authenticator.getUser().then((user) => {
        if (this.featureFlag.MENU_INSIGHTS) {
          if (user.permissoesSolucao.indexOf('PREDICAO_ACIDENTE_VISUALIZAR') !== -1) {
            this.menu.splice(7, 0, {
              label: 'Insights', icon: 'trimble-icon trimble-trimble-symbol', roles: ['PREDICAO_ACIDENTE_VISUALIZAR'],
              submenu: [
                {label: 'ce.insights.seguranca.menuLabel', state: 'root.insights', roles: [['PREDICAO_ACIDENTE_VISUALIZAR', 'DADOS_SENSIVEIS_VISUALIZAR']]}
              ]
            });
          }
        }
      });
    }

    notificationVideoDownload(user) {
      /* eslint-disable */
      const notificationVideoDownloadElement = document.querySelector('#notification-video-download'),
          vfwcNotificationVideoDownloadElement = document.createElement('vfwc-notification-video-download'); /* eslint-enable */
      vfwcNotificationVideoDownloadElement.user = user;
      notificationVideoDownloadElement.appendChild(vfwcNotificationVideoDownloadElement);
    }
  }
  angular
    .module('headerModule', ['FeatureFlagModule', 'uoModule', 'SolucoesModule', 'v3gAuthenticatorModule'])
    .controller('HeaderCtrl', HeaderCtrl);
}());
