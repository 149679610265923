(function () {
  'use strict';

  const LEFT_CLICK = 0,
      MIDDLE_CLICK = 1;

  class EventViewerLinkCtrl {
    constructor($uibModal, Authenticator, $translate, $window, FeaturehubJsSdk, FeatureHubConfigKeys) {
      this.uibModal = $uibModal;
      this.authenticator = Authenticator;
      this.translate = $translate;
      this.window = $window;
      this.visualizar = this.translate.instant('ce.common.visualizar');
      this.tooltip = this.idVideo ? this.translate.instant('ce.common.visualizarVideo') : this.visualizar;
      this.verVideo = this.translate.instant('ce.common.verVideo');
      this.temPermissaoVisualizacaoVideo =
        this.authenticator.hasRole('VIDEO_VISUALIZAR');
      this.text = this.idVideo ? this.verVideo : this.visualizar;
      this.isPremiumTelemetryEnabled = FeaturehubJsSdk.isFeatureEnabled(FeatureHubConfigKeys.FEATURE_PREMIUM_TELEMETRY);
    }

    linkText() {
      return this.hideText ? '' : ' ' + this.text;
    }

    exibirPlayer(event) {
      const isClickOpenInNewTab =
        event.ctrlKey && event.button === LEFT_CLICK ||
        event.button === MIDDLE_CLICK;
      if (isClickOpenInNewTab && this.idVideo) {
        this.window.open(`/video/${this.idVideo}/${this.datahora}`, '_blank');
      } else if (isClickOpenInNewTab && this.snapshotId) {
        this.window.open(
          `/video/${this.snapshotId}/${this.datahora}/${this.latitude}/${this.longitude}` +
          `/${this.tipoEvento}/${this.vehicleId}/${this.vehiclePrefix}/${this.vehiclePlate}/${this.driverName}` +
          `/${this.threshold}/${this.eventDuration}/${this.actualValue}/${this.eventLevel}`,
          '_blank'
        );
      } else if (event.button === LEFT_CLICK) {
        this.uibModal.open({
          component: 'multiVideoPlayer',
          windowTopClass: 'player-container',
          backdrop: false,
          resolve: {
            idVideo: () => this.idVideo,
            snapshotId: () => this.snapshotId,
            datahora: () => this.datahora,
            tipoEvento: () => this.tipoEvento,
            latitude: () => this.latitude,
            longitude: () => this.longitude,
            vehicleId: () => this.vehicleId,
            vehiclePrefix: () => this.vehiclePrefix,
            vehiclePlate: () => this.vehiclePlate,
            driverName: () => this.driverName,
            threshold: () => this.threshold,
            eventDuration: () => this.eventDuration,
            actualValue: () => this.actualValue,
            eventLevel: () => this.eventLevel,
            isPremiumTelemetryEnabled: () => this.isPremiumTelemetryEnabled
          }
        });
      }
    }

    shouldShowLink() {
      return this.idVideo || 
        (this.snapshotId && (this.isPremiumTelemetryEnabled || this.tipoEvento == 'CARONA'));
    }
  }

  angular
    .module('videoPlayer')
    .controller('EventViewerLinkCtrl', EventViewerLinkCtrl)
    .component('eventViewerLink', {
      template: `
      <a ng-if="$ctrl.temPermissaoVisualizacaoVideo && $ctrl.shouldShowLink()"
        ng-class="{'video-link-assistido': $ctrl.isAssistido}"
        ng-mouseup="$ctrl.exibirPlayer($event)"
        uib-tooltip="{{ $ctrl.tooltip }}"
        tooltip-enable="$ctrl.showTooltip"
      >
        <i ng-if="$ctrl.idVideo" class='fa fa-play-circle video-button'/>
        <span ng-if="$ctrl.snapshotId && !$ctrl.idVideo" class='material-symbols-filled'>imagesmode</span>
        {{ $ctrl.linkText() }}
      </a>
      `,
      controller: 'EventViewerLinkCtrl',
      bindings: {
        idVideo: '@',
        snapshotId: '@',
        datahora: '@',
        latitude: '@',
        longitude: '@',
        vehicleId: '@',
        vehiclePrefix: '@',
        vehiclePlate: '@',
        driverName: '@',
        tipoEvento: '@',
        threshold: '@',
        eventDuration: '@',
        actualValue: '@',
        eventLevel: '@',
        isPremiumTelemetryEnabled: '@',
        hideText: '@',
        showTooltip: '@',
        isAssistido: '<'
      }
    });
}());
